import React from "react"
import HeadWithPara from './HeadWithPara';
import Button from './Button';

import { bannerCointainer } from "../styles/banner.module.css"


export default function Banner(props) {
    return (
        <div style={{ backgroundColor: props.color }}>
            <div className={bannerCointainer}>
                <HeadWithPara headerText={props.headerText} paragraphText={props.text} />
                <Button url={props.url} friendlyUrl={props.friendlyUrl} />
            </div>
        </div>
    );
}