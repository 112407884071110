import { graphql, StaticQuery } from "gatsby";
import React from "react"
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import HeadWithPara from "../components/HeadWithPara";
import Layout from "../components/Layout";

import { home, topContainer, quote } from "../styles/home.module.css"

const kryptoData = require('../../static/json/tjen-krypto.json')
const homeData = require('../../static/json/home.json')

export default class Home extends React.Component {
  render() {
    const coinbaseData = kryptoData[1];
    return (
      <Layout>
        <div className={home}>
          <StaticQuery
            query={
              graphql`
                query SiteInfo {
                  site {
                    siteMetadata {
                      title
                      description
                      siteUrl
                    }
                  }
                }
              `
            }
            render={data => (
              <Helmet>
                <meta charSet="utf-8" />
                <title>{data.site.siteMetadata.title}</title>
                <meta name="description" content={data.site.siteMetadata.description} />
                <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
              </Helmet>
            )} />
          <div className={`container ${topContainer}`} style={{
            backgroundImage: `url(/images/bitcoin-cash-circle.svg)`,
            backgroundRepeat: 'repeat',
            backgroundSize: '38px',
            backgroundPosition: 'center',
          }}>
            <div>
              <h1>Bitcoin <span>Cash</span> Norge</h1>
              <p>Din guide til “Peer-to-Peer Electronic Cash”</p>
            </div>
            <img src="/images/undraw_Bitcoin_P2P.svg" alt="Two people transferring Bitcoin Cash to eachother" />
          </div>
          <Banner headerText={coinbaseData.headerText} text={coinbaseData.text} url={coinbaseData.url} friendlyUrl={coinbaseData.friendlyUrl} color={coinbaseData.color} />
          <div className="container">
            <HeadWithPara headerText={homeData.headerText} paragraphText={homeData.text} />
            <div className={quote}>
              <p><cite>"{homeData.quote.quoteText}"</cite></p>
              <p>{homeData.quote.quoteMaker}</p>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}